import {Toast} from "vant";
import router from '../router/router';
import { ElMessage } from 'element-plus';

export function checkResponseFail(response) {
    if(response.data.code !== undefined){
        Toast.clear();
        switch(response.data.code){
            case '1001':
                return false;//成功
            case '40410':
                ElMessage({
                    message: '请先登录！',
                    type:'warning',
                    duration: 4000, // 持续时间，单位是毫秒
                  });
                router.push(`/login`);
                return false;//成功
            default:
                return true;//失败
        }
    }else if(response.data.status !== undefined){
        Toast.clear();
        switch(response.data.status){
            case 200:
                return false;//成功
            case 400:
                return false;//成功 龙珠活动签到
            default:
                return true;//失败
        }
    }else{
        return true; // 默认失败
    }
}

// 表单类型 x-www-form-urlencoded 序列化
const x_www_form_urlencoded_JsonConvert = (jsonObj) => {
    let params = new URLSearchParams();
    for (const key in jsonObj) {
        params.append(key, jsonObj[key]);
    }
    return params;
}
export function addAuthorization(config) {
    const sessionId = localStorage.getItem('sessionId')
    if (sessionId != null) {
        config.headers['sessionId'] = sessionId.replace(/"/g, '')
    } else {
        config.headers['sessionId'] = '1'
    }
    // 兼容 axios x-www-... 内容类型 不会自动序列化
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.data = x_www_form_urlencoded_JsonConvert(config.data);
    }
}

export function get_ACCESS_TOKEN() {
    let token = localStorage.getItem('ACCESS_TOKEN');
    return token || '';
}
export function set_ACCESS_TOKEN(token) {
    localStorage.setItem('ACCESS_TOKEN',token);
}
export function remove_ACCESS_TOKEN() {
    localStorage.removeItem('ACCESS_TOKEN');
}
export function failHandle(response) {

     // 处理一些异常
    ElMessage({
        message: response.data.msg || response.data.message || `Unknown\n${response.config.url.split('/').pop()}`,
        type:'error',
        duration: 5000, // 持续时间，单位是毫秒
      });
}

// 添加接口loading 和 关闭loading
let loadingReqNum = 0; // 请求前收集的loading数量
let loadingRepNum = 0; // 请求后校验loading数量是否正确 对则清除
export function loadingSwitchHandle(configOrResponse) {
    let loading;
    if(configOrResponse.config !== undefined){
        // 代表请求后
        loading = !!configOrResponse.config.loading;
        if(loading){
            loadingRepNum++;
            if(loadingReqNum===loadingRepNum){
                Toast.clear();
            }
        }
    }else{
        // 代表请求前
        loading = !!configOrResponse.loading;
        if(loading){
            loadingReqNum++;
            Toast.loading({
                duration: 0, // 设置0不会消失
                message: '加载中...',
                forbidClick: true,
            });
        }
    }
}



