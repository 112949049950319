import { createApp } from "vue";
import App from "./App.vue";

import {initTheme} from "./util/setTheme";
initTheme();//初始化主题

import './css/base.scss'
import './css/iconfont.scss'
import './css/theme.css'
import './css/style.scss'

import router from "./router/router";
import 'element-plus/dist/index.css'; // 引入样式
import { Toast } from 'vant';
import 'vant/lib/toast/style';

const app = createApp(App)
app.config.globalProperties.$toast = Toast;
app.use(router)
app.mount('#app')
