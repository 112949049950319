export function setTheme(theme = 'light') {
    if(theme === 'light'){
        if(document.documentElement.hasAttribute('theme')){
            document.documentElement.removeAttribute('theme');
            localStorage.removeItem('theme');
        }
    }else{
        // 设置主题
        document.documentElement.setAttribute('theme', theme);
        localStorage.setItem('theme', theme);
    }
}

/*
* 初始化主题 必须优先其他逻辑访问
* TODO 获取上次主题 防止异步通讯app等待 白屏问题
* */
export function initTheme() {
    let val = localStorage.getItem('theme') || 'light';
    setTheme(val);
}

