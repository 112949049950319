<template>
  <div class="app">

   <router-view/>

  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
    
    }
  },
  computed: {
  
  },
  methods:{
   
  },
  created() {
   
  },
  mounted() {
  },
  watch:{
  }
}
</script>

<style>
.app{
  width: 100%;
  height: 100%;
  background-color: white;
}
</style>


