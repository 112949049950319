import axios from 'axios'
import {addAuthorization, failHandle, checkResponseFail, loadingSwitchHandle} from "./base";

// 创建 axios 实例
// const applicationFormData = 'multipart/form-data'
const applicationFormDataXwwwForm = 'application/x-www-form-urlencoded'
const contentType = 'Content-Type'
const request = axios.create({
  baseURL: 'https://www.chatgpt5000.com',
  // baseURL: 'http://192.168.71.3:8081',
  timeout: 999999,
  headers: {
    [contentType]: applicationFormDataXwwwForm,
  }
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    failHandle(error.response)
  }
  return Promise.reject(error)
}

// request 拦截器
request.interceptors.request.use(config => {
  loadingSwitchHandle(config)
  addAuthorization(config)
  return config
}, errorHandler) 

// response 拦截器
request.interceptors.response.use((response) => {
  // loadingSwitchHandle(response)
  // // 返回的业务状态判断
  if(checkResponseFail(response)){
    return errorHandler({response});
  }

  // // 开发期间 检测数据
  // if(isDevAndQa){
  //   testCheckData(response);
  // }

  return response.data
}, errorHandler)

// 表单类型 x-www-form-urlencoded 序列化
// const x_www_form_urlencoded_JsonConvert = (jsonObj) => {
//   let params = new URLSearchParams();
//   for (const key in jsonObj) {
//     params.append(key, jsonObj[key]);
//   }
//   return params;
// }

export default request
