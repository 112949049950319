import { createRouter, createWebHashHistory } from "vue-router";
import Numbers from '../pages/share/numbers.vue';
import Login from "../pages/login/login.vue";
import Download from "../pages/promotion/download.vue";
import Winrecord from "../pages/winrecord/winrecord.vue";
import WinrecordDetail from "../pages/winrecord/winrecordDetail.vue";
import newDownload from "../pages/promotion/newDownload.vue";
import huaweiDownload from "../pages/promotion/huaweiDownload.vue";
import downloadPage from "../pages/promotion/download_page.vue";
// import Home from "../pages/home/home.vue";

export const routes = [
    { path: '/', redirect: '/downloadPage',},
    // { path: '/', name: 'NumbersPage', component: Numbers, meta: { title: '蝌蚪之家'}},
    { path: '/numbers', name: 'NumbersPage', component: Numbers, meta: { title: '号码分享'} },
    { path: '/login', name: 'LoginPage', component: Login, meta: { title: '登录'} },
    { path: '/download', name: 'DownloadPage', component: Download, meta: { title: '蝌蚪之家下载'} },
    { path: '/winrecord', name: 'WinrecordPage', component: Winrecord, meta: { title: '中奖记录'} },
    { path: '/winrecordDetail', name: 'WinrecordDetailPage', component: WinrecordDetail, meta: { title: '中奖详情'} },
    { path: '/newDownload', name: 'HewDownloadPage', component: newDownload, meta: { title: 'app下载'} },
    { path: '/huaweiDownload', name: 'HuaweiDownloadPage', component: huaweiDownload, meta: { title: '华为下载指南'} },
    { path: '/downloadPage', name: 'DownloadPage', component: downloadPage, meta: { title: 'app下载'} },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
  });

  // 全局前置守卫
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '蝌蚪之家';
    next();
  });


export default router;
