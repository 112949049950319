<template>

  <div style="background-color: white;">
    <div class="login">
      <div>
        <img src="./img/loginTop.png">
      </div>

        <div>
          <input type="tel" class="phone" v-model="phone" placeholder="请输入手机号" required>
        </div>
        <div>
          <input type="text" class="smsCode" v-model="smsCode" placeholder="请输入验证码" required>
          <button size="small" type="primary" @click="sendSms" v-if="smsCountDownTimer === null">获取验证码</button>
          <button size="small" type="primary" disabled v-else>{{ smsCountDown }}秒后重试</button>
        </div>
        <div class="submit">
          <button type="submit" @click="onSubmit">登录</button>
        </div>
    </div>
  </div>



</template>


<script>

import { sendSMS, appMobileLogin } from "@/api/api_user";
import { v7 as uuidv7 } from 'uuid';
import { ElMessage } from 'element-plus';

export default {
  name: "LoginPage",
  data() {
    return {
      phone: '',
      smsCode: '',
      smsCountDown: 60,
      smsCountDownTimer: null,
    }
  },
  computed: {},
  methods: {
    onSubmit(values) {
      if (this.phone.length < 11) {
        return ElMessage({
          message: '请填写正确的手机号码！',
          type: 'warning',
          duration: 3000, // 持续时间，单位是毫秒
        });
      }
      if (this.username === '') {
        return ElMessage({
          message: '验证码必须填写！',
          type: 'warning',
          duration: 3000, // 持续时间，单位是毫秒
        });
      }
      console.log('submit', values);
      let uuid = uuidv7();
      appMobileLogin(this.phone, this.smsCode, uuid).then(res => {
        console.log(res);
        localStorage.setItem('sessionId', res)
        ElMessage({
          message: '登录成功！',
          type: 'success',
          duration: 3000, // 持续时间，单位是毫秒
        });
        this.$router.go(-1);
      })
    },
    sendSms() {
      if (this.phone.length < 11) {
        return ElMessage({
          message: '请填写正确的手机号码！',
          type: 'warning',
          duration: 3000, // 持续时间，单位是毫秒
        });
      }
      sendSMS(this.phone).then(res => {
        ElMessage({
          message: res,
          type: 'success',
          duration: 3000, // 持续时间，单位是毫秒
        });
        clearInterval(this.smsCountDownTimer)
        this.smsCountDownTimer = setInterval(() => {
          this.smsCountDown--;
          if (this.smsCountDown === 0) {
            this.smsCountDownTimer = null;
            clearInterval(this.smsCountDownTimer)
          }
        }, 1000);
      })
    },
  },
  created() {
  },
  mounted() { },
  watch: {},
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    clearInterval(this.smsCountDownTimer)
    console.log("已经清楚定时器");
    next();
  },
}
</script>

<style scoped lang="scss">

/* .app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
} */

.login {
  display: flex;
  flex-direction: column;
  /* 设置为列方向 */
  justify-content: start;
  /* 垂直居中对齐 */
  align-items: center;
  /* 水平居中对齐 */
  width: 100%;
  height: 100%;
  background-color: white;

  >div:nth-child(1) {

    margin-top: 80px;
    width: 185px;
    height: 86px;
    >img{
      width: 100%;
      height: 100%;
    }
  }

  >div:nth-child(2) {
    padding: 0 15px;
    background-color: #F7F8FA;
    margin-top: 60px;
    margin-bottom: 20px;
    width: 90%;
    height: 40px;
    border-radius: 20px;

    .phone{
      height: 100%;
      width: 100%;
      background-color: #F7F8FA;
      border-radius: 20px;
      border: none;
      outline: none; /* 去掉外边框 */
      font-size: 16px;
    }
  }

  >div:nth-child(3) {
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    background-color: #F7F8FA;
    margin-bottom: 40px;
    width: 90%;
    height: 40px;
    border-radius: 20px;

    .smsCode{
      height: 100%;
      width: 100%;
      background-color: #F7F8FA;
      border-radius: 20px;
      border: none;
      outline: none; /* 去掉外边框 */
      font-size: 16px;
    }
    >button{
      margin-left: 20px;
      width: 140px;
      color: #3787FF;
      background-color:#F7F8FA;
      font-size: 15px;
      border: none;
    }
  }

  .submit{

    background-color: #F7F8FA;
    width: 90%;
    height: 40px;
    border-radius: 20px;

    >button{
      height: 100%;
      width: 100%;
      color: white;
      background-color:#3787FF;
      font-size: 15px;
      border: none;
      border-radius: 20px;
    }
  }
}
</style>