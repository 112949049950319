<template>
    <div class="app">
        <img src="./img/new_download.png" alt="" style="display: block;width: 100%; padding-bottom: 0px;">
        <div class="downloadType">
            <a href="https://apps.apple.com/cn/app/id6499296466">
                <div class="downLoadBtn" @click="downloadiOS">
                    <img src="./img/ios.png" alt="" style="width: 25px;">
                    <div>
                        <span>iOS</span>
                        <span>v{{iosVersion}}</span>
                    </div>
                </div>
            </a>
            <div class="downLoadBtn" @click="downloadHuaWei">
                <img src="./img/huawei.png" alt="" style="width: 25px;">
                <div>
                    <span>华为</span>
                    <span>v{{androidVersion}}</span>
                </div>
            </div>
            <div class="downLoadBtn" @click="downloadAndroid">
                <img src="./img/android.png" alt="" style="width: 25px;">
                <div>
                    <span>安卓</span>
                    <span>v{{androidVersion}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="guide-page" v-if="isWeChat && showGuidePage">
        <img src="../share/img/guide_page.png" style="width: 100%;">
    </div>
</template>
  
<script>


import { appVersionDownload } from "@/api/api";

export default {
    name: "DownloadPage",
    data() {
        return {
            isWeChat: false,  /// 是否微信
            showGuidePage: false,   /// 微信显示引导页
            data: {},
            iosVersion: '',
            androidVersion: '',
            downLoadUrl: '',
        }
    },
    computed: {

    },
    methods: {
        init() {
            appVersionDownload(this.winId).then(res => {
                console.log(res);
                this.iosVersion = res[0].version;
                this.androidVersion = res[1].version;
                this.downLoadUrl = res[1].url;
            });
        },
        downloadHuaWei() {
            if (this.isWeChat) {
                this.showGuidePage = true;
                return;
            }
            this.$router.push({ path: '/huaweiDownload', query: { androidVersion: this.androidVersion, downLoadUrl: this.downLoadUrl } });
        },
        downloadAndroid() {
            if (this.isWeChat) {
                this.showGuidePage = true;
                return;
            }
            // 文件 URL 或数据
            const fileUrl = this.downLoadUrl;
            // 创建隐藏的可下载链接
            const link = document.createElement('a');
            link.href = fileUrl;
            link.style.display = 'none';
            document.body.appendChild(link);
            // 触发点击
            link.click();
            // 清理
            document.body.removeChild(link);
        },
        downloadiOS() {
            if (this.isWeChat) {
                this.showGuidePage = true;
                return;
            }
        },
    },
    created() {
        const ua = navigator.userAgent.toLowerCase();
        this.isWeChat = /micromessenger/.test(ua);
        this.init();
    }
}

</script>
  
<style scoped lang="scss">
.app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    /* 示例高度 */
    overflow-y: auto;
}

.downloadType {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 16px;
}

.downLoadBtn {
    display: flex;
    flex-direction: row;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: center;
    height: 44px;
    width: 100%;
    border-radius: 22px;
    background: linear-gradient(to right, #76D6FF, #D1BCFF);
    margin-top: 16px;

    >div {
        display: flex;
        flex-direction: column;
        /* 设置为列方向 */
        justify-content: center;
        /* 垂直居中对齐 */
        align-items: start;
        margin-left: 10px;
        >span:nth-child(1) {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            line-height: 1;
        }
        >span:nth-child(2) {
            margin-top: 3px;
            font-size: 12px;
            color: #fff;
            line-height: 1;
        }
    }
}

.guide-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

</style>