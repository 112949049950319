<template>
  <div class="app">
    <img src="./img/download.png" alt="" style="display: block;width: 100%; padding-bottom: 60px;">
    <div style="position: fixed;left: 0;right: 0;bottom: 0;padding: 12px 16px;">
      <a style="
          height: 44px;border-radius: 100px;background: #3787FF;
          display: flex;
          justify-content: center;
          align-items: center;font-size: 15px;color: #FFF;" @click="download">去下载</a>
    </div>
  </div>
</template>

<script>

import { savePv } from "@/api/api";

export default {
  name: "DownloadPage",
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    download() {

      savePv().then(res => {
        console.log(res);
      });

      // 文件 URL 或数据
      const fileUrl = 'https://oss.chatgpt5000.com/app/1.1.20%2B115/kedou.apk';

      // 创建隐藏的可下载链接
      const link = document.createElement('a');
      link.href = fileUrl;
      link.style.display = 'none';
      document.body.appendChild(link);

      // 触发点击
      link.click();

      // 清理
      document.body.removeChild(link);

    }
  },
  created() { }
}
</script>

<style scoped lang="scss">
.app {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  /* 示例高度 */
  overflow-y: auto;
}
</style>