<template>
    <div class="app">
        <div class="winNumber">
            <div class="title">
                <span class="stage">{{type+' '+userWinNumbers.issue}}期</span>
                &nbsp;
                <span class="day">2023-09-12 星期二</span>
            </div>
            
            <div class="kl8Numbers" v-if="userWinNumbers.name === 'kl8'">
                <span v-for="(it, index) in publishLottery.red" :key="index" class="number">
                    {{ it }}
                </span>
            </div>
            <div class="numbers" v-else>
                <span v-for="(it, index) in publishLottery.red" :key="index" class="red-numbers">
                    {{ it }}
                </span>
                <span v-for="(it, index) in publishLottery.blue" :key="index" class="blue-numbers">
                    {{ it }}
                </span>
            </div>
            <div class="bottom">
                中奖信息为AI模拟数据，本APP不提供购彩及兑奖服务
            </div>
        </div>

        <div class="user">
            <img class="photo" :src="avatarUrl" alt="">
            <div>
                <span>恭喜{{ nickName }}用户摇号摇中 <span>{{ userWinNumbers.bonus }}元</span> 大奖</span>
                <img v-if="data.isSvip" src="./img/svip.png" alt="">
            </div>
        </div>

        <div class="myNumber">
            <div class="title">
                <span>本期选号方式为：</span>
                <div>{{ data.model }}</div>
            </div>
            <span class="time">摇号时间：{{ publishLottery.day }}</span>
            <div class="numbers">
                <div>
                    <div>投注：<span>{{ userWinNumbers.count }}</span> 注</div>
                    <div>已中奖</div>
                </div>
                <div>
                    <div>金额：<span>{{ userWinNumbers.amount }}</span> 元</div>
                    <div>奖金：<span>{{ userWinNumbers.bonus }}</span> 元</div>
                </div>
                <div>
                    <span>红球</span>
                    &nbsp; &nbsp;
                    <div class="redNumbers">
                        <span v-for="(it, index) in userWinNumbers.redNumbers" :key="index"
                            :class="[userWinNumbers.hitRedNumbers.some(item => item === it) ? 'on' : 'red']">{{ it }}
                        </span>
                    </div>
                </div>
                <div v-if="blueNumbers.length !== 0">
                    <span>蓝球</span>
                    &nbsp; &nbsp;
                    <div class="blueNumbers">
                        <span v-for="(it, index) in blueNumbers" :key="index"
                            :class="[userWinNumbers.hitBlueNumbers.some(item => item === it) ? 'on' : 'blue']">{{ it }}
                        </span>
                    </div>
                </div>
            </div>

        </div>

        <div class="download">
            <a href="https://chatgpt5000.com/www/app/download.html">我也去摇一摇</a>
        </div>

    </div>
</template>

<script>

import { favouriteDetail } from "@/api/api";

export default {
    name: "WinRecordDetailPage",
    data() {
        return {
            winId: '' ,
            avatarUrl: '',
            nickName: '',
            data: {},
            publishLottery:{},
            userWinNumbers:{},
            blueNumbers:[],
            type:'',
        }
    },
    computed: {},
    methods: {
    },
    created() {
        this.winId = this.$route.query.winId;
        this.avatarUrl = this.$route.query.avatarUrl;
        this.nickName = this.$route.query.nickName;

        favouriteDetail(this.winId).then(res => {
            console.log(res);
            this.data = res;
            this.publishLottery = res.publishLottery;
            this.userWinNumbers = res.userWinNumbers[0];
            if (res.userWinNumbers[0].name === '3d') {
                this.type = '福彩3D';
            } else if (res.userWinNumbers[0].name === 'pl3') {
                this.type = '排列3';
            } else if (res.userWinNumbers[0].name === 'pl5') {
                this.type = '排列5';
            } else if (res.userWinNumbers[0].name === 'ssq') {
                this.type = '双色球';
            } else if (res.userWinNumbers[0].name === 'dlt') {
                this.type = '大乐透';
            } else if (res.userWinNumbers[0].name === 'kl8') {
                this.type = '快乐8';
            }
            if (res.userWinNumbers[0].blueNumbers !== null) {
                this.blueNumbers = res.userWinNumbers[0].blueNumbers;
            }
            
        });
    },
    mounted() {},
    watch: {},
}
</script>

<style scoped lang="scss">
.app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #F9F9FB;
    padding: 16px 15px;
}

.winNumber {
    background-color: white;
    border-radius: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: start;
    /* 水平居中对齐 */

    .title {
        padding: 16px 15px;

        .stage {
            font-size: 18px;
            color: black;
            font-weight: 600;
        }

        .day {
            font-size: 12px;
            color: #666666;
        }
    }

    .numbers {
        display: flex;
        flex-direction: row;
        /* 设置为列方向 */
        justify-content: center;
        /* 垂直居中对齐 */
        align-items: start;
        padding-left: 16px;
        padding-right: 16px;

        .red-numbers {
            background-color: #FF5859;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            border-radius: 100%;
            margin-right: 5px;
            line-height: 1;
            color: white;
            word-wrap: break-word;
        }

        .blue-numbers {
            background-color: #4B85FF;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            border-radius: 100%;
            margin-right: 5px;
            line-height: 1;
            color: white;
            word-wrap: break-word;
        }
    }

    .kl8Numbers {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-gap: 10px;
        width: 100%;
        background-color: white;
        padding-left: 16px;
        padding-right: 16px;

        .number {
            background-color: #FF5859;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            line-height: 1;
            color: white;
            word-wrap: break-word;
            aspect-ratio: 1;
        }

    }

    .bottom {
        margin-top: 15px;
        width: 100%;
        background-color: #FDF0ED;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 10px;
        font-size: 12px;
        color: #C27B6B;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

}

.user {
    display: flex;
    flex-direction: row;
    /* 设置为列方向 */
    justify-content: start;
    /* 垂直居中对齐 */
    align-items: center;
    padding: 16px 12px;
    background-color: white;
    border-radius: 6px;
    margin-top: 15px;

    >img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        margin-right: 10px;
    }

    >div {
        display: flex;
        flex-direction: column;
        /* 设置为列方向 */
        justify-content: center;
        /* 垂直居中对齐 */
        align-items: start;

        >span {
            font-size: 14px;

            >span {
                font-size: 14px;
                font-weight: 600;
                color: red;
            }
        }

        >img {
            width: 44px;
            height: 16px;
        }
    }
}

.myNumber {
    display: flex;
    flex-direction: column;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: start;
    padding: 16px 12px;
    background-color: white;
    border-radius: 6px;
    margin-top: 15px;

    .title {
        display: flex;
        flex-direction: row;
        /* 设置为列方向 */
        justify-content: center;
        /* 垂直居中对齐 */
        align-items: start;

        >span {
            font-size: 14px;
            font-weight: 600;
        }

        >div {
            background-color: #f0f5fe;
            color: #3787FF;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 3px;
        }
    }

    .time {
        margin-top: 5px;
        font-size: 12px;
        color: #999999;
    }

    .numbers {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* 设置为列方向 */
        justify-content: center;
        /* 垂直居中对齐 */
        align-items: start;
        padding: 12px 12px;
        background-color: #FFE3E3;
        border-radius: 6px;
        margin-top: 12px;

        >div:nth-child(1) {
            width: 100%;
            display: flex;
            flex-direction: row;
            /* 设置为列方向 */
            justify-content: space-between;
            /* 垂直居中对齐 */
            align-items: center;
            >div:nth-child(1) {
                font-size: 12px;
                >span{
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            >div:nth-child(2) {
                background-color: red;
                border-radius: 4px;
                padding: 2px 6px;
                font-size: 12px;
                color: white;
            }
        }

        >div:nth-child(2) {
            margin-top: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            /* 设置为列方向 */
            justify-content: space-between;
            /* 垂直居中对齐 */
            align-items: center;
            >div {
                font-size: 12px;
                >span{
                    font-size: 14px;
                    font-weight: 600;
                    color: red;
                }
            }
        }

        >div:nth-child(3) {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-direction: row;
            /* 设置为列方向 */
            justify-content: center;
            /* 垂直居中对齐 */
            align-items: start;

            > span{
                margin-top: 3px;
                width: 35px;
                font-size: 12px;
            }

            .redNumbers{
                display: grid;
                grid-template-columns: repeat(9, 1fr);
                grid-gap: 10px;
                width: 100%;

                 .red {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    line-height: 1;
                    color: #FF5859;
                    word-wrap: break-word;
                    aspect-ratio: 1;
                    font-size: 12px;
                }

                .on{
                    background-color: #FF5859;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    line-height: 1;
                    color: white;
                    word-wrap: break-word;
                    aspect-ratio: 1;
                    font-size: 12px;
                }
            }
        }

        >div:nth-child(4) {
            width: 100%;
            display: flex;
            flex-direction: row;
            /* 设置为列方向 */
            justify-content: center;
            /* 垂直居中对齐 */
            align-items: start;

            > span{
                margin-top: 3px;
                width: 35px;
                font-size: 12px;
            }

            .blueNumbers{
                display: grid;
                grid-template-columns: repeat(9, 1fr);
                grid-gap: 10px;
                width: 100%;

                 .blue {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    line-height: 1;
                    color: #3787FF;
                    word-wrap: break-word;
                    aspect-ratio: 1;
                    font-size: 12px;
                }

                .on {
                    background-color: #3787FF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    line-height: 1;
                    color: white;
                    word-wrap: break-word;
                    aspect-ratio: 1;
                    font-size: 12px;
                }
            }
        }

        
    }

}

.download {
    display: flex;
    flex-direction: row;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: center;
    margin-top: 25px;
    height: 44px;
    width: 100%;
    border-radius: 22px;
    background: linear-gradient(to bottom, #FF9F70, #FF7977);
    >a{
        padding-top: 10px;
        width: 100%;
        height: 100%;
        font-size: 16px;
        color: white;
        font-weight: 600;
        text-align: center;
        display: inline-block;
    }
}


</style>