import request from './request'


export function userInfo() {
    return request.post(
        '/user/userInfoByUserId',
        {},
        {
            loading: true,
        }).then(res=>res.data);
}
export function sendSMS(phone) {
    return request.post(
        '/login/sendSMSByKeDou',
        { "phone": phone },
        {
            loading:true,
        }).then(res=>res.data);
}
export function appMobileLogin(phone, code, uuid) {
    const url = '/login/appMobileLoginByIM';
    var data = {
        'phone': phone,
        'code': code,
        'deviceCode':uuid,
        // "invitationCode": inviteCode,
    };
    return request.post(url,data,{loading:true}).then(res=>res.data);
}