<template>
    <div class="app">
        <div class="topSearch">
            <span style="color: black;">筛选</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button class="button-custom" @click="showBF">
                <span class="button-content">
                    <span class="button-text">{{issueName}}</span>
                    <img v-if="isShowSearchF" class="button-icon" src="./img/shouqi.png" alt=""
                        style="display: block; width: 13px; height: 13px;">
                    <img v-else class="button-icon" src="./img/zhankai.png" alt=""
                        style="display: block; width: 13px; height: 13px;">
                </span>
            </button>
            &nbsp;&nbsp;
            <button class="button-custom" @click="showBS">
                <span class="button-content">
                    <span class="button-text">{{prizeName}}</span>
                    <img v-if="isShowSearchS" class="button-icon" src="./img/shouqi.png" alt=""
                        style="display: block; width: 13px; height: 13px;">
                    <img v-else class="button-icon" src="./img/zhankai.png" alt=""
                        style="display: block; width: 13px; height: 13px;">
                </span>
            </button>
        </div>
        <div ref="containerRef" class="list-container" @scroll="handleScroll">
            <div v-if="refreshing" class="refresh-indicator">
                <span>刷新中...</span>
            </div>
            <div v-for="(item, index) in items" :key="index" class="list-item" @click="pushDetail(item)">
                <div>
                    <img class="photo" :src="item.avatarUrl" alt="">
                    <span>{{ item.nickName }}</span>
                </div>
                <div>
                    <span>{{ item.issue + '期' + ' ' + item.hit }}</span>
                    <span>{{ item.bonus + '元' }}</span>
                </div>
            </div>
            <div v-if="loadingMore" class="load-more-indicator">
                <span>加载中...</span>
            </div>
        </div>

        <div class="searchView" v-if="isShowSearchF" @click="close">
            <ul class="historyView">
                <li class="item" v-for="(item, index) in issueList" :key="index" @click="chooseQi(item)">
                    <img v-if="item.value == issue" src="./img/number_search.png" alt="">
                    <span :class="[item.value == issue ? 'selectText' : 'text']">{{item.name}}</span>
                </li>
            </ul>
        </div>

        <div class="searchView" v-if="isShowSearchS" @click="close">
            <div class="winView">
                <div v-for="(item, index) in prizeList" :key="index" :class="[item.value == prize ? 'grid-item-select' : 'grid-item']" @click="chooseWin(item)">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref, onMounted, onUnmounted, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getAIUserDetailListFilter, getUserWinIssueList} from "@/api/api";
import { defineComponent } from 'vue';

// 使用 defineComponent 来定义组件并设置 name 属性
defineComponent({
    name: 'WinRecordPage',
});

// 状态
const containerRef = ref(null);
const refreshing = ref(false);
const loadingMore = ref(false);
const items = ref([]);
const page = ref(1);
const router = useRouter();
const route = useRoute();
const lotteryCode = route.query.lotteryCode;
const userId = route.query.userId;
const isShowSearchF = ref(false);
const isShowSearchS = ref(false);
const issueList = ref([]);
const prizeList = ref([]);
const issue = ref('0');
const prize = ref('0');
const issueName = ref('历史回顾');
const prizeName = ref('奖等');


const showBF = () => {
    if (isShowSearchF.value === true) {
        isShowSearchF.value = false;
    } else {
        isShowSearchF.value = true;
        isShowSearchS.value = false;
    }
};

const showBS = () => {
    if (isShowSearchS.value === true) {
        isShowSearchS.value = false;
    } else {
        isShowSearchF.value = false;
        isShowSearchS.value = true;
    }
};

const close = () => {
    isShowSearchF.value = false;
    isShowSearchS.value = false;
};

const chooseQi = (item) => {
    issue.value = item.value;
    if (item.name == '全部'){
        issueName.value = '历史回顾';
    } else {
        issueName.value = item.value+'期';
    }
    isShowSearchF.value = false;
    pullToRefresh();
};
const chooseWin = (item) => {
    prize.value = item.value;
    if (item.name == '全部'){
        prizeName.value = '奖等';
    } else {
        prizeName.value = item.name;
    }
    isShowSearchS.value = false;
    pullToRefresh();
};

const pushDetail = (item) => {
    console.log(item);
    router.push({ path: '/winrecordDetail', query: { winId: item.winId, avatarUrl:item.avatarUrl, nickName:item.nickName} });
};


// 下拉刷新
const pullToRefresh = () => {
    refreshing.value = true;
    page.value = 1;
    items.value = [];
    getAIUserDetailListFilter(lotteryCode, userId, page.value, issue.value, prize.value).then(res => {
        items.value = res;
        refreshing.value = false;
    });
};

// 上拉加载更多
const loadMore = async () => {
    if (loadingMore.value || refreshing.value) return;
    loadingMore.value = true;
    page.value++;
    getAIUserDetailListFilter(lotteryCode, userId, page.value, issue.value, prize.value).then(res => {
        const newItems = res;
        if (newItems.length > 0) {
            items.value = [...items.value, ...newItems];
            loadingMore.value = false;
        } else {
            page.value--;
        }
    });
};

// 处理滚动事件
const handleScroll = () => {
    if (!containerRef.value) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.value;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
        loadMore();
    }
};

// 初始化数据
const init = async () => {
    getAIUserDetailListFilter(lotteryCode, userId, page.value, issue.value, prize.value).then(res => {
        items.value = res;
    });
    getUserWinIssueList(lotteryCode, userId).then(res => {
        issueList.value = res.issueList;
        prizeList.value = res.prizeList;
    });
};

// 监听窗口大小变化
const resizeHandler = () => {
    if (refreshing.value) pullToRefresh();
};

// 组件挂载后初始化数据
onMounted(async () => {
    await init();
    window.addEventListener('resize', resizeHandler);
});

// 组件卸载时移除事件监听
onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler);
});

// 监听滚动条位置变化
watchEffect(() => {
    if (refreshing.value) {
        containerRef.value.scrollTop = 0; // 模拟下拉刷新
    }
});
</script>

<style scoped lang="scss">

.app {
    display: flex;
    flex-direction: column;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: center;
    /* 水平居中对齐 */
}

.topSearch {
    display: flex;
    flex-direction: row;
    /* 设置为列方向 */
    justify-content: start;
    /* 垂直居中对齐 */
    align-items: center;
    /* 水平居中对齐 */
    position: absolute;
    padding: 0% 16px;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;

    .button-custom {
        /* 添加一些边距 */
        padding: 0px 5px;
        /* 内边距 */
        background-color: #f7f8fa;
        /* 背景颜色 */
        color: #666666;
        /* 文字颜色 */
        border: none;
        /* 移除默认边框 */
        cursor: pointer;
        /* 改变鼠标指针形状 */
        text-align: center;
        /* 居中文本 */
        text-decoration: none;
        /* 移除文本下划线 */
        display: inline-block;
        /* 使其能够与其它元素一起使用 Flexbox */
        font-size: 14px;
        /* 字体大小 */
        height: 30px;
        width: 120px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .button-content {
            display: flex;
            align-items: center;
        }

        .button-icon {
            width: 24px;
            height: 24px;
            margin-left: 3px;
        }

        .button-text {
            font-size: 14px;
        }

    }
}

.list-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    /* 示例高度 */
    overflow-y: auto;
}

.refresh-indicator,
.load-more-indicator {
    padding: 10px;
    text-align: center;
}

.list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* 垂直居中对齐 */
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;

    >div:nth-child(1) {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            margin-right: 20px;
    
            .photo {
                width: 35px;
                height: 35px;
                border-radius: 100%;
                border: 1px solid #999;
                background-color: #999;
                margin-right: 10px
            }
    
            >span {
                font-size: 14px;
                display: inline-block;
                /* 或者使用 block 如果你想让它独占一行 */
                max-width: 140px;
                /* 根据需要调整宽度 */
                overflow: hidden;
                white-space: nowrap;
                /* 防止文本换行 */
                text-overflow: ellipsis;
                /* 文本溢出时显示省略号 */
            }
        }

    >div:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 520px;

        >span:nth-child(1) {
            font-size: 14px;
        }
        >span:nth-child(2) {
            font-size: 14px;
        }
    }
}

.searchView {
    position: absolute;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(22, 15, 15, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    .historyView {
        background-color: white;
        width: 100%;
        height: 240px;
        border-top: 1px solid #eee;
        overflow-y: auto;

        .item {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            height: 40px;
            width: 100%;
            padding: 0% 15px;

            >img {
                width: 15px;
                height: 15px;
                margin-right: 10px;
            }

            .text {
                font-size: 13px;
            }
            .selectText{
                font-size: 13px;
                color: #3787FF;
            }
        }
    }

    .winView {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        width: 100%;
        background-color: white;
        padding: 15px 15px;

        .grid-item {
            background-color: #F5F5F5;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 100px;
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 隐藏超出的内容 */
            text-overflow: ellipsis;
            /* 当内容被修剪时显示省略标记(...) */
            font-size: 13px;
        }

        .grid-item-select {
            background-color: #EFF5FF;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            border-radius: 100px;
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 隐藏超出的内容 */
            text-overflow: ellipsis;
            /* 当内容被修剪时显示省略标记(...) */
            font-size: 13px;
            color: #3787FF;
        }
    }
}

</style>