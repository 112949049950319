<template>
  <div class="swiper-container">
    <swiper :modules="modules" :navigation="true" :pagination="{ clickable: true }" :autoplay="{ delay: 3000, disableOnInteraction: false }" :loop="true" @swiper="onSwiper" @slideChange="onSlideChange">
      <!-- <swiper-slide v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="slide.alt" class="swiper-image" />
      </swiper-slide> -->

      <swiper-slide>
        <img src="./img/app1.png" class="swiper-image" />
      </swiper-slide>
      <swiper-slide >
        <img src="./img/app2.png" class="swiper-image" />
      </swiper-slide>
      <swiper-slide>
        <img src="./img/app3.png" class="swiper-image" />
      </swiper-slide>
      <swiper-slide>
        <img src="./img/app4.png" class="swiper-image" />
      </swiper-slide>

      <!-- 分页器 -->
      <template #pagination>
        <div class="swiper-pagination"></div>
      </template>

      <!-- 导航按钮 -->
      <template #navigation-prev>
        <div class="swiper-button-prev"></div>
      </template>
      <template #navigation-next>
        <div class="swiper-button-next"></div>
      </template>
    </swiper>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css'; // 引入 Swiper 的样式
import 'swiper/css/navigation'; // 导航按钮样式
import 'swiper/css/pagination'; // 分页器样式
import 'swiper/css/autoplay'; // 自动播放样式

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const modules = [Navigation, Pagination, Autoplay];

    const slides = [
      { image: './img/app1.png', alt: 'Image 1' },
      { image: './img/app2.png', alt: 'Image 2' },
      { image: './img/app3.png', alt: 'Image 3' },
      { image: './img/app4.png', alt: 'Image 4' }
    ];

    const onSwiper = (swiper) => {
      console.log(swiper);
    };

    const onSlideChange = () => {
      console.log('swiper change');
    };

    return {
      modules,
      slides,
      onSwiper,
      onSlideChange
    };
  }
});
</script>

<style scoped>
.swiper-container {
  width: 100%;
  height: 400px; /* 根据需要调整高度 */
}

.swiper-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>